<template>
  <page-list>
    <template #header>
      <el-button type="info" class="fa fa-refresh" @click="getList"></el-button>
      <el-button
        class=""
        type="primary"
        @click="
          editMode = 'add';
          dialogVisible = true;
        "
        >添加</el-button
      >
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        header-align="center"
        align="center"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="昵称"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="password"
        label="密码"
        header-align="center"
        align="center"
        min-width="250px"
      >
      </el-table-column>
      <el-table-column
        prop="groups_text"
        label="所属组别"
        header-align="center"
        align="center"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        header-align="center"
        align="center"
        min-width="150px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        header-align="center"
        align="center"
        min-width="70px"
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{ color: USER_STATUS[scope.row.status].color }"
            >{{ " " + USER_STATUS[scope.row.status].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="logintime"
        label="最后登录时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="dateFormatter"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column label="操作" header-align="center" align="center">
        <template v-slot="scope">
          <el-button
            class="icon-btn fa fa-pencil"
            type="primary"
            @click="editHandle(scope.row)"
          >
            <!-- 编辑 -->
          </el-button>
          <el-button
            class="icon-btn fa fa-trash"
            type="danger"
            @click="delHandle(scope.row)"
          >
            <!-- 删除 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>
  </page-list>
</template>

<script>
import { USER_STATUS } from "@/helpers/constants";
import EditDialog from "./editDialog";
import { getAdminList, deleteAdmin } from "@/apis/auth";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";

export default {
  components: { EditDialog },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "id",
        order: "desc",
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
    };
  },
  created() {},
  mounted() {},
  activated() {
    this.form = cloneDeep(this.initForm);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      getAdminList({ ...this.form, ...this.paginationData })
        .then(({ total, rows }) => {
          this.tableData = rows;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dateFormatter(row) {
      return row.logintime
        ? this.$moment.unix(row.logintime).format("yyyy-MM-DD HH:mm:ss")
        : "无";
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteAdmin(row.id).then(({ code, msg }) => {
          if (code === 1) {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(msg);
          }
        });
      });
    },

    editHandle(row) {
      this.editMode = "edit";
      this.dialogVisible = true;
      this.editData = row;
    },
  },
};
</script>

<style lang="scss" scoped></style>
