<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="所属组别：" prop="groups">
            <el-select v-model="form.groups" placeholder="请选择">
              <el-option
                v-for="(item, index) in AMDIN_GROUP_OPTINOS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名：" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="Email：" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="昵称：" prop="nickname">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item
            v-if="mode === 'edit'"
            label="登录失败次数："
            prop="loginfailure"
          >
            <el-input v-model="form.loginfailure"></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio label="normal">正常</el-radio>
              <el-radio label="hidden">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { AMDIN_GROUP_OPTINOS } from "@/helpers/constants";
import { addAdmin, editAdmin } from "@/apis/auth";
import validators from "@/helpers/validator";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      AMDIN_GROUP_OPTINOS: AMDIN_GROUP_OPTINOS,
      initForm: {
        group: "",
        username: "",
        email: "",
        nickname: "",
        password: "",
        status: "normal",
      },
      form: {},
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          validators.username,
        ],
        groups: [
          { required: true, message: "所属组别不能为空", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email不能为空", trigger: "blur" },
          validators.email,
        ],
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          validators.password,
        ],
      },
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.resetForm();
        this.editReflect();

        if (this.mode === "edit") {
          delete this.rules.password;
        } else {
          this.rules["password"] = [
            { required: true, message: "密码不能为空", trigger: "blur" },
            validators.password,
          ];
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.password = "";
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const promise = this.mode === "edit" ? editAdmin : addAdmin;

        promise(this.form).then(({ code, data }) => {
          if (code === 0 && data["__token__"]) {
            promise(this.form, data["__token__"]).then(({ code, msg }) => {
              if (code === 1) {
                this.close();
                this.$emit("refresh");
                msgSuc("操作成功！");
              } else {
                msgErr(msg);
              }
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
